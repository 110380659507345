import React from 'react';
import { images } from '../../constants';
import Subheading from '../SubHeading/SubHeading'

import './Newsletter.css';

const Newsletter = () => (
  <div className='app__newsletter'>
    <div className='app__newsletter-heading'>
    </div>
  </div>
);

export default Newsletter;
