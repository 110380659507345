import bg from '../assets/bg.jpg';
import nimrod from '../assets/nimrod.jpg';
import gallery02 from '../assets/gallery02.jpeg';
import gallery03 from '../assets/gallery03.jpeg';
import gallery04 from '../assets/gallery04.jpeg';
import gallery05 from '../assets/gallery05.jpeg';
import gallery06 from '../assets/gallery06.jpeg';
import gallery07 from '../assets/gallery07.jpeg';
import gallery08 from '../assets/gallery08.jpeg';
import logo from '../assets/logo.png';
import albums from '../assets/albums.jpg';
import roundlogo from '../assets/roundlogo.png';
import header_img from '../assets/header_img.jpeg'
import waves from '../assets/waves.svg'

export default {
  bg,
  nimrod,
  gallery02,
  gallery03,
  gallery04,
  gallery05,
  gallery06,
  gallery07,
  gallery08,
  logo,
  albums,
  roundlogo,
  header_img,
  waves,
};
